import React, { useRef } from "react";
import { motion, useScroll, useSpring, useTransform, useMotionValue, useVelocity, useAnimationFrame } from "framer-motion";
import { wrap } from "@motionone/utils";

interface ParallaxProps {
  children: string;
  baseVelocity: number;
}

function ParallaxText({ children, baseVelocity = 100}: ParallaxProps) {
  const baseX = useMotionValue(0);
  const { scrollY } = useScroll();
  const scrollVelocity = useVelocity(scrollY);

  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400
  });

  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false
  });

  const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);
  const directionFactor = useRef<number>(1);

  useAnimationFrame((t, delta) => {

    let moveBy = directionFactor.current * baseVelocity * (delta / 1000);

    if (velocityFactor.get() < 0) {
      directionFactor.current = -1;
    } else if (velocityFactor.get() > 0) {
      directionFactor.current = 1;
    }
    moveBy += directionFactor.current * moveBy * velocityFactor.get();
    baseX.set(baseX.get() + moveBy);
  });

  return (
    <div className="overflow-hidden flex flex-nowrap tracking-tight">
      <motion.div className="text-darkBackground dark:text-lightBackground flex flex-nowrap whitespace-nowrap uppercase font-normal text-8xl mobile:text-9xl tablet:text-[10rem] desktop:text-[13rem] font-header" style={{ x }}>
        <span className="block mx-8" style={{ wordSpacing: '2rem' }}>{children} </span>
        <span className="block mx-8" style={{ wordSpacing: '2rem' }}>{children} </span>
        <span className="block mx-8" style={{ wordSpacing: '2rem' }}>{children} </span>
        <span className="block mx-8" style={{ wordSpacing: '2rem' }}>{children} </span>
      </motion.div>
    </div>
  );
}

export const SectionHeader = ({text, className=""}: {text: string, className?: string}) => {

  return (
    <section className={`${className}`}>
      <ParallaxText baseVelocity={-5}>{text}</ParallaxText>
    </section>
  );
}
