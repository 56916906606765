import * as React from "react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

const accordions = [
  {
    id: 1,
    title: "Full-Stack Web Development",
    text: "I bring your ideas to life with efficient, dynamic, and reliable web development. Using the latest front-end frameworks and back-end technologies, I create responsive, cross-browser websites and custom web applications tailored to your needs. My approach ensures flawless functionality, testing, and adherence to the latest industry standards for optimal performance.",
  },
  {
    id: 2,
    title: "Ongoing Website Maintenance",
    text: "Keep your website running smoothly with regular updates, content management, and security patches. I monitor performance, back up data, and resolve any technical issues promptly, ensuring your website remains secure, fast, and reliable so you can focus on running your business.",
  },
  {
    id: 3,
    title: "Custom UI Design",
    text: "Create visually appealing and user-oriented website designs that leave a lasting impression. I ensure consistent branding, design custom graphics, and develop responsive layouts optimised for different devices. By working closely with clients, I craft unique designs that reflect their vision and provide an exceptional user experience.",
  },
  {
    id: 4,
    title: "Targeted SEO Services",
    text: "Improve your online visibility with tailored SEO strategies. I conduct keyword research, optimise website content, and implement on-page SEO techniques to boost your search engine rankings. I also monitor analytics and provide insights to help drive organic traffic and improve your online presence.",
  },
  {
    id: 5,
    title: "Mobile App Development",
    text: "I specialise in developing efficient, user-orientated mobile applications using Java and the MVVM architecture. This approach ensures a clear separation between the user interface and business logic, making the apps easier to maintain and test. My expertise includes integrating APIs and optimising performance for Android platforms. With a focus on scalable, well-structured code, I aim to deliver applications that are both functional and responsive.",
  },
];

export default function ServiceAccordion() {
  return (
    <div className="space-y-8 max-w-3xl desktop:max-w-5xl w-full mx-auto t">
      {accordions.map((accordion) => (
        <Accordion key={accordion.id} type="single" collapsible>
          <AccordionItem value={`item-${accordion.id}`}>
            <AccordionTrigger>{accordion.title}</AccordionTrigger>
            <AccordionContent>{accordion.text}</AccordionContent>
          </AccordionItem>
        </Accordion>
      ))}
    </div>
  );
}
