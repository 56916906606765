import React from 'react'
import {motion} from 'framer-motion'

interface CustomText {
    text: string,
    className?: string
}

const quote = {
    initial: {
        opacity: 1,
    },
    animate: {
        opacity: 1,
        transition: {
            delay: 0.5,
        }
    }
}

const singleQuote = {
    initial: {
        opacity: 0,
        y:50
    },
    animate: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 1,
        }
    }
}

const AnimatedServiceText = ({text, className=""}: CustomText) => {
  return (
    <div className='dark:text-lightText w-full flex justify-center items-center'>
        <motion.h3 className={`max-w-2xl w-full mx-auto text-center ${className}`}
        variants={quote}
        initial="initial"
        animate="animate">
          {text.split(" ").map((word,index) =>
          <motion.span key={word + '-' + index} className='inline-block'
          variants={singleQuote}
          initial="initial"
          animate="animate"
          >
            {word}&nbsp;
          </motion.span>
          )
        }
        </motion.h3>
    </div>
  )
}

export default AnimatedServiceText