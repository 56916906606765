import React from "react";
import { motion } from "framer-motion";

interface CustomText {
  text: string;
  className?: string;
  justify?: "center" | "start";
}

const quote = {
  initial: {
    opacity: 1,
  },
  animate: {
    opacity: 1,
    transition: {
      delay: 0.5,
    },
  },
};

const singleQuote = {
  initial: {
    opacity: 0,
    y: 50,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
    },
  },
};

const AnimatedHeader = ({ text, className = "", justify = "start" }: CustomText) => {
  return (
    <div className={`w-full flex justify-center desktop:justify-${justify} items-center mx-auto`}>
      <motion.h1
        className={` ${className}`}
        variants={quote}
        initial="initial"
        animate="animate"
      >
        {text.split(" ").map((word, index) => (
          <motion.span
            key={word + "-" + index}
            className="inline-block"
            variants={singleQuote}
            initial="initial"
            animate="animate"
          >
            {word}&nbsp;
          </motion.span>
        ))}
      </motion.h1>
    </div>
  );
};

export default AnimatedHeader;
